import React from 'react';
import Styles from "./styles.module.scss";
import Footer from '../../components/footer';
import classnames from "classnames";
import Slider from "react-slick";
import InnerPageBanner from "../../components/innerbanner";
import aromaLogo from '../../assets/images/logo-about.png';
import diffuser1 from '../../assets/images/lemon_diffuser.png';
import diffuser2 from '../../assets/images/tropical_diffuser.png';
import diffuser3 from '../../assets/images/lemongrass-diffuser.png';
import diffuser4 from '../../assets/images/lemon_diffuser.png';

import star from '../../assets/images/star.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import diffusirstarbg from '../../assets/images/diffusirstarbg1.png';
import diffusirstarbg2 from '../../assets/images/diffusirstarbg1.png';
import diffusirstarbg3 from '../../assets/images/diffusirstarbg1.png';
import diffusirstarbg4 from '../../assets/images/diffusirstarbg1.png';
import diffusirstarbg5 from '../../assets/images/diffusirstarbg1.png';
import amber_places1 from '../../assets/images/amber-places1.png';
import amber_places2 from '../../assets/images/amber-places2.png';
import amber_places3 from '../../assets/images/amber-places3.png';
import amber_places4 from '../../assets/images/amber-places4.png';
import amber_places5 from '../../assets/images/amber-places5.png';

import introduction from '../../assets/images/aroma_tab_01.svg';
import Demonstration from '../../assets/images/aroma_tab_02.svg';
import Installation from '../../assets/images/aroma_tab_03.svg';
import Refilling from '../../assets/images/aroma_tab_04.svg';
import foodanddrink from '../../assets/images/aroma_tab_05.svg';

import brochureImage from '../../assets/images/download_brochure.jpg';
import graph80 from '../../assets/images/80-graph.png';
import graph100 from '../../assets/images/100-graph.png';


function AmberDiffuser() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <div className={Styles.solutionsBanner}>
        <InnerPageBanner/>
      </div>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutDescription}>
            <figure>
              <img src={aromaLogo} />
            </figure>
            <h2>amber aroma</h2>
            <p>Essential oils are obtained from flowers, leaves, bark, roots, or other components of the plant using a variety of methods. The oil is then purified through distillation or through cold-pressing of the plant material, which breaks up the molecules in the oil into a liquid form. This separates the oil from other parts of the plant, allowing it to be extracted for use. Oils can also be obtained through the process of expression, which involves using the parts of the plant where the oils are concentrated (such as the rind of citrus fruits or the leaves.
            </p>
            <p>  Essential oils are a type of natural product, extracted from plants. They are typically classified according to the plant from which they were extracted, such as citrus oils or lavender oil. They can also be classified according to other characteristics, such as their therapeutic properties. There are two main ways of extracting essential oils: distillation and expression.</p>
          </div>
        </div>
      </section>
      <section className={Styles.aboutCompany}>
        <div className="container">
          <div className={Styles.whatweprovide}>
            <p>[AMBER SOLUTIONS]</p>
          </div>
          <h2>Amber Aroma</h2>
          <div className={classnames(Styles.productCards, "card_direction")}>
            <div className={Styles.cardData}>
              <h1>[ AMBER POPULARAroma ]</h1>
              <h3>Fresh Lemon </h3>
              <p>The best lemon perfumes uplift the mood, stimulate 
the nervous system, and make your body feel energized. 
Choose your favorite's fragrance from Amber's wide 
selection of admiring and entrancing aromas, which are 
created to suit all tastes. More freshness, a healthier 
setting, and quieter areas.</p>
              <a href="/#/contact" className="btn btn-secondary2 m-t-20">
                Contact Now
              </a>
            </div>
            <section className="slider-products">
              <Slider {...settings}>
                <figure className={Styles.cardImage}>
                  <img src={diffuser1} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffuser2} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffuser3} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffuser4} />
                </figure>
              </Slider>
            </section>
          </div>
        </div>
      </section>
      <section className={Styles.diffuserCompany}>
        <div className="container">
          <div className={Styles.whatweprovide}>
            <p>[ AMBER AROMA ]</p>
          </div>
          <h2>Category of Aroma</h2>
          <section className={Styles.currentSection}>
            <div className="tabStyleAroma">
              <Tabs>
                <TabList>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={introduction} />
                      <p>Citrus & Fresh</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={Demonstration} />
                      <p>Floral</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={Installation} />
                      <p>Woody</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={Refilling} />
                      <p>Oriental</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={foodanddrink} />
                      <p>Food & Drink</p>
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>
                    Citrus Fresh Essential Oil is a fun blend of orange, tangerine, mandarin, grapefruit, lemon, and spearmint. This blend is high in antioxidants,
supports immunity, and creates an upbeat feeling. Induces feelings of well-being, creativity, and joy. This oil can be used in all 3 ways
                    </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>
                    Essential oils derived from flowering botanical sources are referred to as floral scents.
These floral essential oils include clary sage, chamomile, geranium, jasmine, juniper berry, lavender, rose, and ylang ylang.
                    </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>From Cedarwood & Pine to Juniper, these woody aromas will leave you feeling restored. These oils tend to bring a bass note to your blends and are 
beautiful choices when needing to ground yourself. Try introducing these essential oils to your yoga or meditation practice. </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>In the purest tradition of Arabia, Oriental Woods Oil draws its effectiveness from 3 precious vegetable oils, Argan oil, sesame oil and macadamia oil to
which is added a hint of natural sandalwood essential oil to give it all its nobility and prestige. </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>There's a whole host of essential oils like orange, rosemary, vanilla and rose, which are packed with invigorating aromas and flavors that will enhance the
taste of the dish you create. You can add just a few drops of an essential oil in your main meals, salads, marinades, dips or even desserts </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
              </Tabs>
            </div>
          </section>
        </div>
      </section>
      <section className={Styles.downloadBrochure}>
        <div className="container">
          <div className={Styles.brochureWrapper}>
            <figure><img src={brochureImage} alt="" /></figure>
            <div className={Styles.brochureText}>
              <h2>DOWNLOAD OUR
                DIFFUSER & AROMA
                BROCHURE</h2>
              <a href="http://" className="btn btn-secondary2 m-t-20">
                Download Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.aboutCompany1}>
        <div className="container">
          <div className={classnames(Styles.productCards1, "card_direction")}>
            <div className={Styles.cardData1}>
              <h1>[ AROMA TYPES ]</h1>
              <h2>Fresh Lemon</h2>
              <div className={Styles.star}>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
              </div>
              <p>The best lemon perfumes uplift the mood,
stimulate the nervous system, and make
your body feel energized. </p>
              <div className={Styles.graphBLock}>
                <h5>INTERIOR VOLUME</h5>
                <div className={Styles.spanDescrip}>
                  <img src={graph80} /><span>80%</span></div>
                <h5>Freshness</h5>
                <div className={Styles.spanDescrip}>
                  <img src={graph100} /><span>100%</span></div>
                <h5>Range</h5>
                <div className={Styles.spanDescrip}>
                  <img src={graph100} /><span>100%</span></div>
              </div>
            </div>
            <section className="slider-products-amber">
              <Slider {...settings}>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg2} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg3} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg4} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg5} />
                </figure>
              </Slider>
            </section>
          </div>
        </div>
      </section>
      <section className={Styles.Amber_places}>
        <div className="container">
          <h2>Lemon Grass recommended places</h2>
          <div className={Styles.whatweprovide}>
            <p>A whiff of fragrance can elicit fond memories and a range of feelings that can uplift the spirit,
enrich the emotions, and result in a general sensation of happiness.</p>
          </div>
        </div>
        <div className={Styles.services}>
          <div className={Styles.serviceItem}>
            <img src={amber_places1} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places2} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places3} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places4} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places5} />
          </div>
        </div>
      </section>
      
      <div className='footerBgStyle_4'>
        <Footer/>  
      </div>
    </>
  );
}

export default AmberDiffuser;
