import React from 'react';
import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerPageBanner from "../../components/innerbanner";
import Testimonials from "../../components/testimonials";
import AboutPic from '../../assets/images/about_pic.png';
import ourVisionIcon from '../../assets/images/vision_icon.png';
import visionVideoIcon from '../../assets/images/play_button.png';
import ourValueIcon from '../../assets/images/value_icon.png'; 
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';

function About() {
  return (
    <>
      <div className={Styles.aboutBanner}>
        <InnerPageBanner/>
      </div>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutGrid}>
            <div className={Styles.aboutPic}>
              <div className={Styles.aboutImage}>
                <span  className={classnames(Styles.aboutSeal, "rotate")}></span>   
                <img src={AboutPic} />
              </div>
            </div>
            <div className={Styles.aboutContent}>
              <h1>[ ABOUT COMPANY ]</h1>
              <h2>Best of Amber</h2>
            <p className="para-margin"> Amber was founded and operated in the UAE. Our living and working environments must be of the utmost quality. By spreading its alluring fragrance across the area, amber contributes to it</p>
              <p>Once you sign up for Amber Fragrance, you notice that your area changes naturally.
Amber always stands for energizing your surroundings with its lively yet calming fragrance.{" "} </p>
              
              {/* <a href="http://" className="btn btn-primary m-t-20"> 
                view More
              </a> */}
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.ourPhilosophy}>
          <div className="container">
            <h1>[ ABOUT COMPANY ]</h1>
            <h2>Our Philosophy</h2>
            <div className={Styles.ourPhilosophyBlocks}>
              <a href="https://" className={Styles.ourVision}>
                <img src={ourVisionIcon} alt="our Vision"/>
                <span>Our vision</span>
              </a>
              <a href="https://" className={Styles.ourVideo}>
                <img src={visionVideoIcon} alt="Video"/>
              </a>
              <a href="https://" className={Styles.ourValue}>
                <img src={ourValueIcon} alt="our Value"/>
                <span>Our Value</span>
              </a>
            </div>
          </div>
      </section>
      <section className={Styles.currentSection}>
         <div className="container">
         <p>Fill your space with aroma. Your mood and emotions may vary drastically as a result. You instantly feel self-assured and in a terrific, good mood when you put on a fragrance that you enjoy. </p>
         </div>
         <div className={Styles.currentWrap}>
            <div className={Styles.blockItem}>
              <h1>[ 180<sup>+</sup> ]</h1>
              Current Clients
            </div>
            <div className={Styles.blockItem}>
              <h1>[ 10<sup>+</sup> ]</h1>
              Years of experience
            </div>
            <div className={Styles.blockItem}>
              <h1>[ 35<sup>+</sup> ]</h1>
              Award winning
            </div>
            <div className={Styles.blockItem}>
              <h1>[ 5<sup>+</sup> ]</h1>
              Branches 
            </div>
         </div>
      </section>
      <section className={Styles.whatWeDo}>
        <div className="container">
          <div className={Styles.aboutGrid}>
            <div className={Styles.aboutContent}>
              <h1>[ SEE WHAT WE CAN DO]</h1>
              <h2>Sent Marketing Possibilities</h2>
              <Accordion className="accordionWrap">
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                          HIGH QUALITY INGREDIENTS
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                            Exercitation in fugiat est ut ad ea cupidatat ut in
                            cupidatat occaecat ut occaecat consequat est minim minim
                            esse tempor laborum consequat esse adipisicing eu
                            reprehenderit enim.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                          FRAGRANCE ANY SIZED AREA
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                          Our scent delivery systems feature advanced diffusion technology
                          that converts liquid fragrance into a fine, dry mist and releases it
                          directly into your environment and can be connected to HVAC/Air
                          Con, Wall Mounted or Floor Standing.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                          SPECIALLY FORMULATED FRAGRANCES
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                          Our scent delivery systems feature advanced diffusion technology
                          that converts liquid fragrance into a fine, dry mist and releases it
                          directly into your environment and can be connected to HVAC/Air
                          Con, Wall Mounted or Floor Standing.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            </div>
            <div className={Styles.aboutPic}>
              <div className={Styles.aboutImage}>
                <span  className={classnames(Styles.aboutSeal, "rotate")}></span>   
                <img src={AboutPic} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.feelFragrance}>
        <div className="container">
          <h2>Feel the Fragrance</h2>
        </div>
      </section>
      {/* <section className={Styles.testimonials}>
          <div className="container">
            <h1>[ TESTIMONIALS ]</h1>
            <h2>What People Say</h2>
            <Testimonials/>
          </div>
      </section> */}
    </>
  );
}

export default About;
