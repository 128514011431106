import React from 'react';
import classnames from "classnames";
import { Link } from "react-router-dom";
import Styles from "./styles.module.scss";
import diffuser1 from '../../assets/images/product_011.png';
import diffuser02 from '../../assets/images/product_022.png';
import diffuser03 from '../../assets/images/product_03.jpg';



function WhatWeProvide() {
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  return (
    <>
      <section className={Styles.aboutCompany}>
        <div className="container">
            
            <h2>What We Are Provide</h2>
            <div className={Styles.whatweprovide}>
                <p>A splash of fragrance has the ability to spark cherished memories and a wealth of emotions that can lift spirits, enrich emotions, and lead to an overall feeling of happiness. </p>
            </div>
            <div className={classnames(Styles.productCards, "card_direction")}>
              <div className={Styles.cardData}>
                <h1>[ Best Diffusers in town]</h1>
                <h2>Amber Diffuser</h2>
                <p>Amber Aroma diffuser machines will easily integrate into any area, whether you're searching for a diffuser machine that is wall-mounted, ceiling-mounted, or floor standing. Aroma diffusers are becoming increasingly popular as a way to create a pleasant and relaxing environment.</p>
                <Link to="/amberdiffuser" onClick={scrollToTop} className="btn btn-secondary2 m-t-20">
                View more
                </Link>
              </div>
              <figure className={Styles.cardImage}>
                <img src={diffuser1}/>
              </figure>
            </div>
            <div className={Styles.productCards}>
              <figure className={Styles.cardImage}>
                <img src={diffuser02}/>
              </figure>
              <div className={Styles.cardData}>
                <h1>[ Best AROMA in town]</h1>
                <h2>Amber Aroma</h2>
                <p>To date, Amber Aroma has over 15 different trademark fragrance scents to ensure the greatest variety of choice and to flavor the air with consistent blending to deliver an improved customer experience. Amber Aroma sources the highest quality essential oils.. </p>
                <Link to="/amberaroma" onClick={scrollToTop} className="btn btn-secondary2 m-t-20">
                View More
                </Link>
              </div>
            </div>
            <div className={classnames(Styles.productCards, "card_direction")}>
              <div className={Styles.cardData}>
                <h1>[Best aroma s in town ]</h1>
                <h2>Amber Samples</h2>
                <p>To assist you in selecting the ideal fragrance for you, we provide samples of our distinctive perfumes. Use one of our iconic fragrances to give your business the perfect ambiance. each of the three samples offers a unique aroma that can be enjoyed in different ways.</p>
                <Link to="/ambersamples" onClick={scrollToTop} className="btn btn-secondary2 m-t-20">
                View More
                </Link>
              </div>
              <figure className={Styles.cardImage}>
                <img src={diffuser03}/>
              </figure>
            </div>
        </div>
      </section>
    </>
  );
}

export default WhatWeProvide;
