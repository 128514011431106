import React from 'react';
import Styles from "./styles.module.scss";
import Footer from '../../components/footer';
import classnames from "classnames";
import InnerPageBanner from "../../components/innerbanner";
import aromaLogo from '../../assets/images/logo-about.png';
import diffuser02 from '../../assets/images/amber_aroma.jpg';
import diffuser03 from '../../assets/images/lemon_diffuser.png';
import BrochureDownload from "../../components/brochureDownload";
import solution1 from '../../assets/images/solutions_01.jpg';
import solution2 from '../../assets/images/solutions_02.jpg';
import solution3 from '../../assets/images/solutions_03.jpg';
import solution4 from '../../assets/images/solutions_04.jpg';
import IncredibleAroma from "../../components/incredibleAroma";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import WhatWeProvide from '../../components/whatweprovide';


function AmberSamples() { 
  return (
    <>
      <div className={Styles.solutionsBanner}>
        <InnerPageBanner/>
      </div>
      <div className="container">
          <div className={Styles.aboutDescription}>
            <h1>comming soon</h1>
          </div>
        </div>
       < div className='footerBgStyle_6'>
        <Footer/>  
      </div>
    </>
  );
}

export default AmberSamples;
