import React from 'react';
import Styles from "./styles.module.scss";
import Footer from '../../components/footer';
import classnames from "classnames";
import Slider from "react-slick";
import InnerPageBanner from "../../components/innerbanner";
import aromaLogo from '../../assets/images/logo-about.png';
import diffuser1 from '../../assets/images/product_011.png';
import star from '../../assets/images/star.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import diffusirstarbg from '../../assets/images/diffusirstarbg.png';
import diffusirstarbg2 from '../../assets/images/diffusirstarbg2.png';
import diffusirstarbg3 from '../../assets/images/diffusirstarbg3.png';
import diffusirstarbg4 from '../../assets/images/diffusirstarbg4.png';
import diffusirstarbg5 from '../../assets/images/diffusirstarbg5.png';
import amber_places1 from '../../assets/images/amber-places1.png';
import amber_places2 from '../../assets/images/amber-places2.png';
import amber_places3 from '../../assets/images/amber-places3.png';
import amber_places4 from '../../assets/images/amber-places4.png';
import amber_places5 from '../../assets/images/amber-places5.png';
import introduction from '../../assets/images/01_tab.svg';
import Demonstration from '../../assets/images/02_tab.svg';
import Installation from '../../assets/images/03_tab.svg';
import Refilling from '../../assets/images/04_tab.svg';
import brochureImage from '../../assets/images/download_brochure.jpg';
import graph80 from '../../assets/images/80-graph.png';
import graph100 from '../../assets/images/100-graph.png';


function AmberDiffuser() {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return (
    <>
      <div className={Styles.solutionsBanner}>
      <InnerPageBanner />
      </div>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutDescription}>
            <figure>
              <img src={aromaLogo} />
            </figure>
            <h2>amber aroma</h2>
            <p>Essential oils are obtained from flowers, leaves, bark, roots, or other components of the plant using a variety of methods. The oil is then purified through distillation or through cold-pressing of the plant material, which breaks up the molecules in the oil into a liquid form. This separates the oil from other parts of the plant, allowing it to be extracted for use. Oils can also be obtained through the process of expression, which involves using the parts of the plant where the oils are concentrated (such as the rind of citrus fruits or the leaves.
            </p>
            <p>  Essential oils are a type of natural product, extracted from plants. They are typically classified according to the plant from which they were extracted, such as citrus oils or lavender oil. They can also be classified according to other characteristics, such as their therapeutic properties. There are two main ways of extracting essential oils: distillation and expression.</p>
          </div>
        </div>
      </section>
      <section className={Styles.aboutCompany}>
        <div className="container">
          <div className={Styles.whatweprovide}>
            <p>[AMBER SOLUTIONS]</p>
          </div>
          <h2>Amber Diffuser</h2>
          <div className={classnames(Styles.productCards, "card_direction")}>
            <div className={Styles.cardData}>
              <h1>[AMBER POPULAR DIFFUSERS]</h1>
              <h3>AMBER H</h3>
              <p>Scent Machine employs our unique nebulizing technology,
                which uses filtered air to diffuse fragrances instead of
                heat or water. This preserves the aromatic integrity of
                the oils, allowing for longer-lasting diffusion that leaves
                no residue. Designed to suit spaces of all sizes, it allows
                unrivalled user control,</p>
              <a href="/#/solutions" className="btn btn-secondary2 m-t-20">
                Contact Now
              </a>
            </div>
            <section className="slider-products">
              <Slider {...settings}>
                <figure className={Styles.cardImage}>
                  <img src={diffuser1} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffuser1} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffuser1} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffuser1} />
                </figure>
              </Slider>
            </section>
          </div>
        </div>
      </section>
      <section className={Styles.diffuserCompany}>
        <div className="container">
          <div className={Styles.whatweprovide}>
            <p>[ AMBER DIFFUSERS]</p>
          </div>
          <h2>Working Process</h2>
          <section className={Styles.currentSection}>
            <div className="tabStyle1">
              <Tabs>
                <TabList>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={introduction} />
                      <p>Current Clients</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={Demonstration} />
                      <p>Demonstration</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={Installation} />
                      <p>Installation</p>
                    </div>
                  </Tab>
                  <Tab>
                    <div className={Styles.blockItem}>
                      <img src={Refilling} />
                      <p>Refilling</p>
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>
                      Scent Aroma Diffusers increase your health and wellbeing by releasing nanoparticles and purify the air. Our fragrance diffusers are ideal for
                      creating a unique and inviting ambience in your home and getting the maximum health benefits from your essential oils.
                    </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>
                      Demonstration Dummy text here
                    </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>Refilling Dummy text here </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
                <TabPanel>
                  <div className='diffuser-description'>
                    <p>Installation Dummy text here </p>
                  </div>
                  <a href="http://" className="btn btn-primary m-t-20">
                    cONTACT nOW!
                  </a>
                </TabPanel>
              </Tabs>
            </div>
          </section>
        </div>
      </section>
      <section className={Styles.downloadBrochure}>
        <div className="container">
          <div className={Styles.brochureWrapper}>
            <figure><img src={brochureImage} alt="" /></figure>
            <div className={Styles.brochureText}>
              <h2>DOWNLOAD OUR
                DIFFUSER & AROMA
                BROCHURE</h2>
              <a href="http://" className="btn btn-secondary2 m-t-20">
                Download Now
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className={Styles.aboutCompany1}>
        <div className="container">
          <div className={classnames(Styles.productCards1, "card_direction")}>
            <div className={Styles.cardData1}>
              <h1>[ Diffuser types ]</h1>
              <h2>Amber T</h2>
              <div className={Styles.star}>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
                <div className={Styles.starbiX1}>
                  <img src={star} />
                </div>
              </div>
              <p>tempor incididunt ut labore et dolore magna
                aliqua. Quis ipsum suspendisse
                ultrices gravida</p>
              <div className={Styles.graphBLock}>
                <h5>INTERIOR VOLUME</h5>
                <div className={Styles.spanDescrip}>
                  <img src={graph80} /><span>80%</span></div>
                <h5>Freshness</h5>
                <div className={Styles.spanDescrip}>
                  <img src={graph100} /><span>100%</span></div>
                <h5>Range</h5>
                <div className={Styles.spanDescrip}>
                  <img src={graph100} /><span>100%</span></div>
              </div>
            </div>
            <section className="slider-products-amber">
              <Slider {...settings}>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg2} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg3} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg4} />
                </figure>
                <figure className={Styles.cardImage}>
                  <img src={diffusirstarbg5} />
                </figure>
              </Slider>
            </section>
          </div>
        </div>
      </section>
      <section className={Styles.Amber_places}>
        <div className="container">
          <h2>Amber T recommended places</h2>
          <div className={Styles.whatweprovide}>
            <p>A whiff of fragrance can elicit fond memories and a range of feelings that can uplift the spirit, enrich the
              emotions, and result in a general sensation of happiness.</p>
          </div>
        </div>
        <div className={Styles.services}>
          <div className={Styles.serviceItem}>
            <img src={amber_places1} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places2} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places3} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places4} />
          </div>
          <div className={Styles.serviceItem}>
            <img src={amber_places5} />
          </div>
        </div>
      </section>
      <div className='footerBgStyle_5'>
        <Footer/>  
      </div>
      
    </>
  );
}

export default AmberDiffuser;
