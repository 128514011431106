import React from 'react';
import classnames from "classnames";
import { Link } from "react-router-dom";
import Styles from "./styles.module.scss";
import diffuser1 from '../../assets/images/product_011.png';
import diffuser02 from '../../assets/images/product_022.png';
import diffuser03 from '../../assets/images/product_03.jpg';
import brochureImage from '../../assets/images/download_brochure.jpg';




function BrochureDownload() {


  return (
    <>
      <section className={Styles.downloadBrochure}>
        <div className="container">
            <div className={Styles.brochureWrapper}>
              <figure><img src={brochureImage} alt=""/></figure>
              <div className={Styles.brochureText}>
                <h2>DOWNLOAD OUR
                  DIFFUSER & AROMA
                  BROCHURE</h2>
                  <a href="http://" className="btn btn-primary m-t-20">
                  Download Now
                </a>
              </div>
            </div>
        </div>
      </section>
    </>
  );
}

export default BrochureDownload;
