import React from 'react';
import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerPageBanner from "../../components/innerbanner";
import locationIcon from '../../assets/images/location_icon.png';
import mailIcon from '../../assets/images/mail_icon.png';
import phoneIcon from '../../assets/images/call_icon.png';
import flowerimg from '../../assets/images/flower.png';
import twtIcon from '../../assets/images/twt_icon.png';
import fbIcon from '../../assets/images/fb_icon.png';
import inIcon from '../../assets/images/in_icon.png';
import instaIcon from '../../assets/images/insta_icon.png';
import contactImage from '../../assets/images/contact_image1.png';
import Footer from '../../components/footer';


function Contact() {

  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  
  return (
    <>
      <div className={Styles.contactBanner}>
        <InnerPageBanner />
      </div>
      <section className={Styles.contactDescription}>
        <div className="container">
          <div className={Styles.contactWrapper}>
            <div className={Styles.contactItem}>
              <div className={Styles.contactBlock}>
                <h1>[ reserve your aroma ]</h1>
                <h4>Contact Us!</h4>
                <p>Enjoy the way nature transforms your surroundings and makes the air rich and energizing rather than stuffy.
                  The organic components combined with the fresh fragrances of Amber are the ideal way to liven up your area. </p>
                <p>Choose your favorite fragrance from Amber's wide selection of admiring and entrancing aromas, which are created to suit all tastes. More freshness, a healthier setting, and quieter areas.
                </p>
                <div className={Styles.floweriamge}>
                  <img src={flowerimg}></img>
                </div>
              </div>

              <div className={Styles.contactBlock}>
                <h3>Get in Touch</h3>
                <sub>Your email address will not be published. Required fields are marked *</sub>

                <form 
              method='POST' 
              name='contactForm' 
              className='contactForm'>

              <input type='hidden' name='form-name' value='contactForm'  />

              <div className="form-group">
                <input  type='text'  name='name'  placeholder='Full Name*' className="form-control" required />
              </div>
                <div className="form-group">
                  <input  type='email'  name='email'  placeholder='Email*' className="form-control" required/>
                </div>
                <div className="form-group">
                <textarea className="form-control" name='comments'  placeholder="massage*" required></textarea>
                </div>
                <div className={Styles.contactbutton}>
                  <button className="btn btn-primary m-t-20 ">
                  Send Now!
                  </button></div>

            </form>

              </div>
            </div>
            <div className={Styles.contactItem}>
              <div className={Styles.contactBlock}>
                <div className={Styles.ourcontact}>

                  <h3>Let's Start a Project</h3>
                  <p>Give us a call or drop by anytime, we endeavour to
                    answer all enquiries within 24 hours on business days.
                    We will be happy to answer your questions.</p>
                  <div className={Styles.contactItemb}>
                    <img src={locationIcon}></img>
                    <h6>Our Address </h6>
                    <p>Jumbo Plastic Building – A Block,
                      Abu Dhabi - United Arab Emirate</p>
                  </div>
                  <div className={Styles.contactItemb}>
                    <img src={mailIcon}></img>
                    <h6>Our Emial </h6>
                    <p>
                      <a href="mailto:sales@amberaroma.com">sales@amberaroma.com</a>
                    </p>
                  </div>
                  <div className={Styles.contactItemb}>
                    <img src={phoneIcon}></img>
                    <h6>Our hotline </h6>
                    <p><a href="tel:+971569989380">+971  50  492  0021</a></p>
                  </div>
                  <div className={Styles.socialbuttons}>
                    {/* <a href="https://"><img src={twtIcon}></img></a> */}
                    <a href=" https://www.facebook.com/Amber-Aroma-112216024929510/?ref=pages_you_manage" target="_blank"><img src={fbIcon}></img></a>
                    <a href="https://www.linkedin.com/company/amber-aroma/about/?viewAsMember=true" target="_blank"><img src={inIcon}></img></a>
                    <a href="https://www.instagram.com/amberaroma.ae/" target="_blank"><img src={instaIcon}></img></a>
                  </div>
                </div>
              </div>
              <div className={Styles.contactBlock}>
                <figure className={Styles.contactImage}>
                  <img src={contactImage} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className='footerBgStyle_3'>
        <Footer/>  
      </div>
    </>
  );
}

export default Contact;
