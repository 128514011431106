import React from 'react';
import classnames from "classnames";
import Styles from "./styles.module.scss"; 
import Slider from "react-slick";   
import { Link } from "react-router-dom";

 
function Banner() { 

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };
    

    return (
      <>  
       
        <section className="slider-wrapper">
       
            <Slider {...settings}>
                <div className={Styles.sliderItem}>
          
                    <div className={Styles.slidertext}>
                      <div className={Styles.slidertextContainer}>
                        
                        <h1>New Level of Fragrance</h1>
                        <p>
                        Fill your space with aroma. Your mood and emotions may vary drastically as a result. You instantly feel self-
assured and in a terrific, good mood when you put on a fragrance that you enjoy. Your brain instantly
determines whether a fragrance is pleasant or unpleasant the minute you are exposed to it.
                        </p>
                        <Link to="/casestudy" onClick={scrollToTop} className="btn btn-secondary1 m-t-30">View projects</Link>
                      </div>
                    </div>
                    <div className={Styles.bannerEffects}> </div>
                </div>
                <div className={Styles.sliderItem}>
             
                    <div className={Styles.slidertext}>  
                      <div className={Styles.slidertextContainer}>
                        <h1>amber aroma</h1>
                        <p>
                        Fill your space with aroma. Your mood and emotions may vary drastically as a result. You instantly feel self-
assured and in a terrific, good mood when you put on a fragrance that you enjoy. Your brain instantly
determines whether a fragrance is pleasant or unpleasant the minute you are exposed to it.
                        </p>
                        <Link to="/casestudy" onClick={scrollToTop} className="btn btn-secondary1 m-t-30">Know More</Link>
                      </div>
                    </div>
                    <div className={Styles.bannerEffects}> </div>
                </div>
            </Slider>
            <div className={Styles.bannerSocial}>
                <a href="https://www.instagram.com/amberaroma.ae/"  target="_blank">instagram</a>
          <a href="https://www.facebook.com/people/Amber-Aroma/100084368515285/?ref=pages_you_manage" target="_blank">
            facebook</a>
          <a href="https://www.linkedin.com/authwall?trk=bf&trkInfo=AQGWD5_DE6SR4wAAAYXd6vy4pWRmzBDfRwjiSquLKKKIOAAEBaKVDa4_ONg1tw58fzHuQCE5h-QviNolc9rKskj4ekYjL27kBomqh40xtwPkUUOK-kkIJ_soMfwmQPGAKjrbXRk=&original_referer=&sessionRedirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Famber-aroma%2Fabout%2F%3FviewAsMember%3Dtrue" target="_blank">Linkedin</a>
    
      
      </div>
            <video className='sliderBg' autoPlay muted loop>
                <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/video.webm" type="video/webm"/>
                <source src="https://s3-metasoft.s3.us-east-2.amazonaws.com/video.mp4" type="video/mp4"/>
            </video>
        </section>
      </>
  );
}

export default Banner;