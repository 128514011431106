import React, { useState } from "react";
import Styles from "./styles.module.scss";
import { Link } from "react-router-dom";


function IncredibleAroma() {

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
        });
      };

    return (
        <>
        <section className={Styles.incredibleAroma}>
            <div className="container">
            <div className={Styles.incredibleWrapper}>
                <div className={Styles.incredibleAromaData}>
                <h3>Get Incredible Aroma Right Now!</h3>
                <p>Fragrances are the essence of Amber. We create fragrances for all categories including small offices,
                    hotels, restaurants, hospitals, and so on. We value the ultimate happiness of our customers..</p>
                </div>
                <Link to="/contact" onClick={scrollToTop} className="btn btn-primary m-t-20">
                    Contact now
                </Link>
            </div>
            </div>
        </section>
        </>
  );
}


export default IncredibleAroma;
