import React from 'react';
import Styles from "./styles.module.scss";
import classnames from "classnames";
import Footer from '../../components/footer';
import InnerPageBanner from "../../components/innerbanner";
import aromaLogo from '../../assets/images/logo-about.png';
import diffuser02 from '../../assets/images/amber_aroma.jpg';
import diffuser03 from '../../assets/images/lemon_diffuser.png';
import BrochureDownload from "../../components/brochureDownload";
import solution1 from '../../assets/images/solutions_01.jpg';
import solution2 from '../../assets/images/solutions_02.jpg';
import solution3 from '../../assets/images/solutions_03.jpg';
import solution4 from '../../assets/images/solutions_04.jpg';
import IncredibleAroma from "../../components/incredibleAroma";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';
import WhatWeProvide from '../../components/whatweprovide';


function Solutions() {
  return (
    <>
      <div id='top'></div>
      <div className={Styles.solutionsBanner}>
        <InnerPageBanner/>
      </div>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutDescription}>
            <figure>
              <img src={aromaLogo} />
            </figure>
            <h2>amber aroma</h2>
            <p>A splash of fragrance has the ability to spark cherished memories and a wealth of emotions that
can lift spirits, enrich emotions, and lead to an overall feeling of happiness.</p>
          </div>
        </div>
      </section>
      <WhatWeProvide/>
      <IncredibleAroma/>
      <section className={Styles.whatWeDo}>
        <div className="container">
          <div className={Styles.aboutGrid}>
            <div className={Styles.aboutContent}>
              <h1>[ Best aroma s in town ]</h1>
              <h2>What is the <br/>Benefits</h2>
              <Accordion className="accordionWrapWhite">
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                          Lovely Fragrances
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                          There will not be any malodors or any kind of
                          smells that can disturb the beauty of your interiors.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                          A Warm and Friendly Appeal
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Sweet-smelling interiors are often considered clean ones, and thus,
                        you will also have an appreciation for keeping the office clean.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                    <AccordionItemHeading>
                        <AccordionItemButton>
                        Provides a Soothing Ambience
                        </AccordionItemButton>
                    </AccordionItemHeading>
                    <AccordionItemPanel>
                        <p>
                        Scent diffusers or scented candles with their lovely fragrances can 
                        create a cool and soothing ambience in your home, office, hotel.
                        </p>
                    </AccordionItemPanel>
                </AccordionItem>
            </Accordion>
            </div>
            <div className={Styles.aboutPic}>
              <div className={Styles.aboutImage}>
                <img src={solution1} />
                <img src={solution2} />
                <img src={solution3} />
                <img src={solution4} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <BrochureDownload/>
      <div className='footerBgStyle_2'>
        <Footer/>  
      </div>
    </>
  );
}

export default Solutions;
