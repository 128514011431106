import React, {useState} from 'react';
import { Link } from "react-router-dom";
import logo from '../../assets/images/maberaroma_logo.png';
import Styles from "./styles.module.scss";
import "./_footer.scss";
import classnames from "classnames";
import locationIcon from '../../assets/images/location_icon.png';
import mailIcon from '../../assets/images/mail_icon.png';
import phoneIcon from '../../assets/images/call_icon.png';
import footer from '../../assets/images/footer.png';
import twtIcon from '../../assets/images/twt_icon.png';
import fbIcon from '../../assets/images/fb_icon.png';
import inIcon from '../../assets/images/in_icon.png';
import instaIcon from '../../assets/images/insta_icon.png';

function Footer() {

  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);

  return (
    <>
      <section className={Styles.footer}>
        <div className="container">
          <div className={Styles.footerLinks}>
            <div className={Styles.footerInfo}>
              <Link to="/"><img src={logo} className={Styles.logo} alt='logo' height='80'/></Link>
              <p>
              Benefits can be seen in any industry.<br></br>
Contact Amber Aroma today  to get started!
              </p>
              <div className={Styles.socialbuttons}>
                {/* <a href="https://"><img src={twtIcon}></img></a> */}
                <a href=" https://www.facebook.com/Amber-Aroma-112216024929510/?ref=pages_you_manage" target="_blank"><img src={fbIcon}></img></a>
                <a href="https://www.linkedin.com/company/amber-aroma/about/?viewAsMember=true" target="_blank"><img src={inIcon}></img></a>
                <a href="https://www.instagram.com/amberaroma.ae/" target="_blank"><img src={instaIcon}></img></a>
              </div>
            </div>
            <div className={Styles.footerLinkWrap}>
              <div className={Styles.footerItem}>
                <h5>Contacts</h5>
                <div className={Styles.contactItem}>
                  <img src={locationIcon}></img>
                  <h6>Our Address</h6>
                  <p>Dubai - United Arab Emirates</p>
                </div>
                <div className={Styles.contactItem}>
                  <img src={mailIcon}></img>
                  <h6>Our mailbox</h6>
                  <p> 
                    <a href="mailto:sales@amberaroma.com">sales@amberaroma.com</a>
                  </p>
                </div>
                <div className={Styles.contactItem}>
                  <img src={phoneIcon}></img>
                  <h6>Our phone</h6>
                  <p>+971 56 998 9380</p>
                  <p>+971 56 998 9381</p>
                </div>
              </div>
              <div className={Styles.footerItem}>
                <h5>Latest Projects</h5>
                <p>Calicut Paragon</p>
                <p>African Eastern</p>
                  <p>Oxid spa</p>
              </div>
              <div className={Styles.footerItem}>
                <h5>Subscribe</h5>
                <div className={Styles.subscribe}>
                  <input type="text" placeholder="YOUR EMAIL *"/>
                  <button type="button" className={Styles.send}>send</button>
                </div>
                
                <p>Follow our newsletter to stay updated about agency.</p>
              </div>
            </div>
          </div>
        </div>
       
      
        <div className={Styles.copyright}>
    
         
          <div className={classnames(Styles.copyrightWrap, "container")}>
         
            <span>Copyright &copy; 2023 amber aroma. all rights reserved.</span>
            <span>Developed by <a href="https://metasoftit.com/" target="_blank">MIS</a></span>
            <span><a href="http://">Terms of Use</a><span className={Styles.divider}>|</span><a href="http://">Privacy Environmental Policy</a></span>
          </div>
        </div>
        
        <button className={Styles.scrollTop} onClick={scrollToTop}>Scroll Top</button>
        <button className={Styles.scrollTop1}> <a href="https://wa.me/+971569989380?text=Hi%2C" target="_blank">whatsapp/vinayak (sales)</a></button>
      </section>
    </>
  );
}

export default Footer;
