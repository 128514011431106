import React from 'react';
import { Link, useLocation } from "react-router-dom";
import logo from '../../assets/images/maberaroma_logo.png';
import Styles from "./styles.module.scss";
import classnames from "classnames";



function InnerPageBanner() { 

  const location = useLocation();

  const getCurrentTitle = () => {

    switch (location.pathname) {

      case "/about":
        return "About Us";
        
      case "/solutions":
        return "Amber Solutions";

      case "/amberdiffuser":
        return "Amber Diffuser";

      case "/amberaroma":
        return "Amber Aroma";

      case "/ambersamples":
        return "Amber sample";

      case "/casestudy":
        return "Case studies";

      case "/contact":
        return "Contact us";


    }

  };

  const getParentTitle = () => {
    switch (location.pathname) {

      case "/amberdiffuser":
        return "- Amber solutions";

      case "/amberaroma":
        return "- Amber solutions";

      case "/ambersamples": 
        return "- Amber solutions";

    }
  };

  return (
    <section className={Styles.innerpange}>
      <div className={Styles.innerpageText}>
          <div className="container">
            <h2>{getCurrentTitle()}</h2>
            <p><Link to="/">about us</Link> <Link to="/solutions">{getParentTitle()}</Link> - {getCurrentTitle()}</p>
          </div>
      </div>
  </section>
  );
}

export default InnerPageBanner;