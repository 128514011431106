import React from 'react';
import classnames from "classnames";
import { Link } from "react-router-dom";
import Styles from "./styles.module.scss";
import Footer from '../../components/footer';
import Banner from "./banner";
import WhatWeProvide from "../../components/whatweprovide";
import BrochureDownload from "../../components/brochureDownload";
import AboutPic from '../../assets/images/about_pic.png';
import ourVisionIcon from '../../assets/images/Vector2.png';
import visionVideoIcon from '../../assets/images/play_button.png';
import ourValueIcon from '../../assets/images/Vector.png';
import ourVisionIcon1 from '../../assets/images/Vectorimg.png';
import IncredibleAroma from "../../components/incredibleAroma";
import ourValueIcon1 from "../../assets/images/vectorHover.png"; 


function Home() {


  const scrollToTop = () => {
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  return (
    <>
      <div id='top'></div>
      <Banner/>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutGrid}>
            <div className={Styles.aboutPic}>
              <div className={Styles.aboutImage}>
                <span  className={classnames(Styles.aboutSeal, "rotate")}></span>   
                <img src={AboutPic} />
              </div>
            </div>
            <div className={Styles.aboutContent}>
              <h1>[ ABOUT COMPANY ]</h1>
              <h2>Welcome to Amber</h2>
              <p>
              Amber was founded and operated in the UAE. Our living and working environments must be of the utmost quality. By spreading its alluring fragrance across the area, amber contributes to it. Once you sign up for Amber Fragrance, you notice that your area changes naturally.

Amber always stands for energizing your surroundings with its lively yet calming fragrance..{" "}
              </p>
              <p>
                
              </p>
              <Link to="/casestudy" onClick={scrollToTop} className="btn btn-primary m-t-20">
                view Projects
                
              </Link>
            </div>
          </div>

        </div>
      </section>
      <section className={Styles.ourPhilosophy}>
          <div className="container">
            <h1>[ ABOUT COMPANY ]</h1>
          <h2>Our Philosophy</h2>
            <div className={Styles.ourPhilosophyBlocks}>
              <div className={Styles.ourVision}>
                <div className={Styles.swap_image}>
                  <img src={ourVisionIcon} alt="our Vision"/>
                  <img src={ourVisionIcon1} alt="our Vision" className={Styles.hoverimage}/>
                </div>
                <span>Our vision</span>
                <p>our goal is to offer a variety of fragrance products to consumers while placing a great value on their satisfaction.</p>
              </div>
              <div className={Styles.ourVideo}>
                <img src={visionVideoIcon} alt="Video"/>
              </div>
              <div className={Styles.ourValue}>
                <div className={Styles.swap_image}>
                  <img src={ourValueIcon} alt="our Value"/>
                  <img src={ourValueIcon1} alt="our Value" className={Styles.hoverimage}/>
                </div>
                <span>Our Value</span>
                <p>Amber Aroma places a high importance on Assuring its customers that they will recive fragrance and diffuser solutions / products that are Memorable, Best, Efficient, and Reasonable.</p>
              </div>
            </div>
          </div>
      </section>

      <section className={Styles.currentSection}>
      <div className="container">
         <div className={Styles.currentWrap}>
            <h1>Fill your space with aroma. Your mood and emotions may vary drastically as a result. You instantly feel self-
assured and in a terrific, good mood when you put on a fragrance that you enjoy. Your brain instantly
determines whether a fragrance is pleasant or unpleasant the minute you are exposed to it.</h1>
         </div>
         </div>
      </section>

      <WhatWeProvide/>

      <BrochureDownload/>
      
      <section className={Styles.feelFragrance}>
        <div className="container">
          <h2>“FEEL THE FRAGRANCE”</h2>
        </div>
      </section>
      <IncredibleAroma/>

      <div className='footerBgStyle_1'>
        <Footer/>  
      </div>
    </>
  );
}

export default Home;
