import React from 'react';
import { BrowserRouter as Router, Routes, Route, HashRouter} from 'react-router-dom';
import Header from './components/header';
// import Footer from './components/footer';
import Home from './pages/home';
import About from './pages/about';
import Solutions from './pages/solutions';
import Shop from './pages/shop';
import Contact from './pages/contact';
import Casestudy from './pages/casestudies';
import AmberDiffuser from './pages/amberdiffuser';
import AmberAroma from './pages/amberaroma';
import AmberSamples from './pages/ambersamples';

function App() {
  return (
    <HashRouter>
        <Header/> 
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About/>} />
          <Route path='/solutions' element={<Solutions/>} />
          <Route path='/amberdiffuser' element={<AmberDiffuser/>} />
          <Route path='/amberaroma' element={<AmberAroma/>} />
          <Route path='/ambersamples' element={<AmberSamples/>} />
          <Route path='/casestudy' element={<Casestudy/>} />
          <Route path='/shop' element={<Shop/>} />
          <Route path='/contact' element={<Contact/>} />
        </Routes>
        {/* <Footer/>   */}
    </HashRouter>
  );
}

export default App;