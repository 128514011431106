import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Styles from "./styles.module.scss";
import logo from "../../assets/images/maberaroma_logo.png";
import popupLogo from "../../assets/images/amaberaroma_logo_2.png";
import qrCode from "../../assets/images/qr_code.png";
import brochureImage from "../../assets/images/download_brochure.jpg";

function Header() {

  const [openMenu, setOpenMenu] = useState(false);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };


  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
    });
  };

  return (
    <>
      <nav className={Styles.nav}>
        <div className="container">
          <div className={Styles.navWrapper}>
            <NavLink to="/"  onClick={scrollToTop}>
              <img src={logo} className={Styles.logo} alt="logo" />
            </NavLink>
            <div className={Styles.menuContainer}>
              <div className={Styles.menu}>
                <NavLink
                  to="/"
                  className={({ isActive }) => (isActive ? Styles.active : "")}
                  onClick={scrollToTop}>
                  ABOUT US
                </NavLink>
                <div className={Styles.menuWrap}>
                  <NavLink
                    to="/solutions"
                    className={({ isActive }) => (isActive ? Styles.active : "")}
                    onClick={scrollToTop}>
                    AMBER SOLUTIONS
                  </NavLink>
                  <div className={Styles.submenu}>
                    <NavLink
                      to="/amberdiffuser"
                      className={({ isActive }) => (isActive ? Styles.active : "")}
                      onClick={scrollToTop} >
                      Amber Diffuser
                    </NavLink>
                    <NavLink
                      to="/amberaroma"
                      className={({ isActive }) => (isActive ? Styles.active : "")}
                      onClick={scrollToTop}>
                      Amber Aroma
                    </NavLink>
                    <NavLink
                      to="/ambersamples"
                      className={({ isActive }) => (isActive ? Styles.active : "")}
                      onClick={scrollToTop}>
                      Amber Samples
                    </NavLink>
                  </div>
                </div>
                {/* <NavLink
                  to="/gifting"
                  className={({ isActive }) => (isActive ? Styles.active : "")}
                >
                  CORPORATE GIFTING
                </NavLink> */}
                <NavLink
                  to="/casestudy"
                  className={({ isActive }) => (isActive ? Styles.active : "")}
                  onClick={scrollToTop}>
                  CASE STUDIES
                </NavLink>
                {/* <NavLink
                  to="/shop"
                  className={({ isActive }) => (isActive ? Styles.active : "")}
                >
                  SHOP
                </NavLink> */}
                <NavLink
                  to="/contact"
                  onClick={scrollToTop}
                  className={({ isActive }) => (isActive ? Styles.active : "")}
                  >
                  CONTACT US
                </NavLink>
              </div>
              <button className={Styles.menuBtn} onClick={toggleMenu}>
                menu
              </button>
            </div>
          </div>
        </div>
      </nav>
      {/* Popup menu */}
      <div className={openMenu ? Styles.popupMenuOpen : Styles.popupMenu}>
        <button className={Styles.close} onClick={toggleMenu}>
          close
        </button>
        <div className={Styles.popupWrap}>
          <figure className={Styles.popupLogo}>
              <img src={popupLogo} />
          </figure>
          <div className={Styles.menu} onClick={toggleMenu}>
            {/* <NavLink
              to="/"
            >
              HOME
            </NavLink> */}
            <NavLink
              to="/about"
              className={({ isActive }) => (isActive ? Styles.active : "")}
              onClick={scrollToTop}>
              ABOUT US
            </NavLink>
            <NavLink
              to="/solutions"
              className={({ isActive }) => (isActive ? Styles.active : "")}
              onClick={scrollToTop} >
              AMBER SOLUTIONS
            </NavLink>
            {/* <NavLink
              to="/gifting"
              className={({ isActive }) => (isActive ? Styles.active : "")}
            >
              CORPORATE GIFTING
            </NavLink> */}
            <NavLink
              to="/casestudy"
              className={({ isActive }) => (isActive ? Styles.active : "")}
              onClick={scrollToTop}>
              CASE STUDIES
            </NavLink>
            {/* <NavLink
              to="/shop"
              className={({ isActive }) => (isActive ? Styles.active : "")}
            >
              SHOP
            </NavLink> */}
            <NavLink
              to="/contact"
              className={({ isActive }) => (isActive ? Styles.active : "")}
              onClick={scrollToTop} >
              CONTACT US
            </NavLink>
          </div>
          <h3>Connect With Us</h3>
          <div className={Styles.connectus}>
            <figure>
              <img src={qrCode} />
            </figure>
            <div className={Styles.connecttxt}>
            Enjoy the way nature transforms your surroundings and makes the air rich and energizing rather than stuffy.
              <a href="https://wa.me/+971569989380?text=Hi%2C" target="_blank">whatsapp/vinayak (sales)</a>
            </div>
          </div>
          <div className={Styles.hidemobileView}>
            <h3>DOWNLOAD OUR AROMA & DIFFUSER BROCHURE</h3>
            <figure className={Styles.brochureImage}>
              <img src={brochureImage} />
            </figure>
            <a href="/#/contact" onClick={scrollToTop} className="btn btn-secondary btn-small m-t-30">
              Know More
            </a>
          </div>
        </div>
      </div>
      {/* Popup menu */}
    </>
  );
}

export default Header;
