import React from 'react';
import Styles from "./styles.module.scss";
import classnames from "classnames";
import InnerPageBanner from "../../components/innerbanner";
import aromaLogo from '../../assets/images/logo-about.png';
import diffuser01 from '../../assets/images/product_01.jpg';
import diffuser02 from '../../assets/images/product_02.jpg';
import diffuser03 from '../../assets/images/product_03.jpg';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel} from 'react-accessible-accordion';


function Shop() {
  return (
    <>
      <div className={Styles.shopsBanner}>
      <InnerPageBanner/>
      </div>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutDescription}>
            <figure>
              <img src={aromaLogo} />
            </figure>
            <h2>amber aroma</h2>
            <p>
            A whiff of fragrance can elicit fond memories and a range of feelings that can uplift the spirit, enrich the emotions, and result in a general sensation of happiness.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className={Styles.feelFragrance}>
        <div className="container">
          <h2>Feel the Fragrance</h2>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Quis ipsum suspendisse ultrices gravida.</p>
        </div>
      </section>
      <section className={Styles.aboutCompany}>
        <div className="container">
          <div className={classnames(Styles.productCards, "card_direction")}>
              <div className={Styles.cardData}>
                <h1>[ Best Diffusers in town]</h1>
                <h2>Amber Diffuser</h2>
                <p>Amber Aroma diffuser machines will easily integrate into any area, whether you're searching for a diffuser machine that is wall-mounted, ceiling-mounted, or floor standing. </p>
                {/* <a href="http://" className="btn btn-primary m-t-20">
                Shop now
                </a> */}
                <a href="/#/contact" className="btn btn-primary m-t-20">
                    Contact now
                </a>
              </div>
              <figure className={Styles.cardImage}>
                <img src={diffuser01}/>
              </figure>
            </div>
            <div className={Styles.productCards}>
              <figure className={Styles.cardImage}>
                <img src={diffuser02}/>
              </figure>
              <div className={Styles.cardData}>
                <h1>[ AMBER SOLUTIONS ]</h1>
                <h2>Amber Aroma</h2>
                <p>To date, Amber Aroma has over 15 different trademark fragrance scents to ensure the greatest variety of choice and to flavor the air with consistent blending to deliver an improved customer experience. Amber Aroma sources the highest quality essential oils. </p>
                {/* <a href="http://" className="btn btn-primary m-t-20">
                view Aroma
                </a> */}
                <a href="/#/contact" className="btn btn-primary m-t-20">
                    Contact now
                </a>
              </div>
            </div>
            <div className={classnames(Styles.productCards, "card_direction")}>
              <div className={Styles.cardData}>
                <h1>[ AMBER SOLUTIONS ]</h1>
                <h2>Amber Diffuser</h2>
                <p>Amber Aroma diffuser machines will easily integrate into any area, whether you're searching for a diffuser machine that is wall-mounted, ceiling-mounted, or floor standing.  </p>
                {/* <a href="http://" className="btn btn-primary m-t-20">
                view Diffuser
                </a> */}
                <a href="/#/contact" className="btn btn-primary m-t-20">
                    Contact now
                </a>
              </div>
              <figure className={Styles.cardImage}>
                <img src={diffuser03}/>
              </figure>
            </div>
        </div>
      </section>
      
    </>
  );
}

export default Shop;
