import React from 'react';
import Styles from "./styles.module.scss";
import Footer from '../../components/footer';
import classnames from "classnames";
import InnerPageBanner from "../../components/innerbanner";
import aromaLogo from '../../assets/images/logo-about.png';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import case1 from '../../assets/images/case_0.jpg';
import case2 from '../../assets/images/case_0.jpg';
import case3 from '../../assets/images/case_0.jpg';
import case4 from '../../assets/images/case_0.jpg';
import case5 from '../../assets/images/case_0.jpg';
import case6 from '../../assets/images/case_0.jpg';
import IncredibleAroma from "../../components/incredibleAroma";


function Casestudy() {
  return (
    <>
      <div className={Styles.caseBanner}>
      <InnerPageBanner />
      </div>
      <section className={Styles.about}>
        <div className="container">
          <div className={Styles.aboutDescription}>
            <figure>
              <img src={aromaLogo} />
            </figure>
            <h2>amber aroma</h2>
            <p>
              A splash of fragrance has the ability to spark cherished memories and a wealth of emotions that
              can lift spirits, enrich emotions, and lead to an overall feeling of happiness.{" "}
            </p>
          </div>
        </div>
      </section>
      <section className={Styles.caseBg}>
        <div className="container">
          <div className="tabStyle">
            <Tabs>
              <TabList>
                <Tab>RESTAURANT</Tab>
                <Tab>MEDICAL</Tab>
                <Tab>HOTEL</Tab>
                <Tab>RETAIL</Tab>
                <Tab>SPA</Tab>
              </TabList>
              <TabPanel>
                <div className={Styles.casestudiesWrap}>
                  <div className={Styles.caseItem}>
                    <figure><img src={case1}></img></figure>
                    <figcaption><h5>Calicut Paragon</h5> <p> Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case2}></img></figure>
                    <figcaption><h5>Delhi Darbar </h5><p>Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case3}></img></figure>
                    <figcaption><h5>Adaminde Chayakkada</h5>
                      <p>Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case4}></img></figure>
                    <figcaption><h5>Dhe Puttu</h5>
                      <p>Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case5}></img></figure>
                    <figcaption><h5>Al Yemen Restaurant</h5>
                      <p>Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Delmon</h5>
                      <p>Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Raffe</h5>
                      <p>Dubai - United Arab Emirates</p></figcaption>
                  </div>

                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Anjappar</h5>
                      <p>Dubai - United Arab Emirates</p></figcaption>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={Styles.casestudiesWrap}>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Emirates hospitals</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Aster Pharmacy</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Aster Pharmacy</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>

                </div>
              </TabPanel>
              <TabPanel>
                <div className={Styles.casestudiesWrap}>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Retro GYM</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={Styles.casestudiesWrap}>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>African Eastern</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Sports Zone</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Saturn Night General Trading</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className={Styles.casestudiesWrap}>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Jeevadhara</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Snow Drops</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  < div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Oxid Spa</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                  <div className={Styles.caseItem}>
                    <figure><img src={case6}></img></figure>
                    <figcaption><h5>Jeevandhara - II</h5><p>
                      Dubai - United Arab Emirates</p></figcaption>
                  </div>
                </div>
              </TabPanel>
            </Tabs>

          </div>
        </div>
      </section>

      <IncredibleAroma />
        
      <div className='footerBgStyle_6'>
        <Footer/>  
      </div>

    </>
  );
}

export default Casestudy;
